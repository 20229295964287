type AccountLinkItem = { href: string; title: string; class?: string };

export default function () {
    const { t } = useI18n();
    const localePath = useLangPath();
    const isUserInRole = useIsUserInRole();

    const isAdmin =
        isUserInRole(Role.Admin) ||
        isUserInRole(Role.ReportingAdmin) ||
        isUserInRole(Role.MemberAdmin) ||
        isUserInRole(Role.TechnicalAdmin) ||
        isUserInRole(Role.ContentAdmin);

    return computed<AccountLinkItem[]>(() =>
        [
            { href: localePath("/my-account"), title: t("Layout.AccountDetails") },
            isAdmin ? { href: "/administration", title: t("Layout.LinkAdministration") } : null,
            isUserInRole(Role.Distributor)
                ? { href: "/distributor/landing", title: t("Layout.LinkDistributorLanding") }
                : null,
            isUserInRole(Role.Manufacturer)
                ? { href: "/manufacturer/landing", title: t("Layout.LinkManufacturerLanding") }
                : null,
            isUserInRole(Role.LeadTimeMonthly) || isUserInRole(Role.LeadTimeWeekly)
                ? { href: localePath("/lead-time-reporting"), title: t("Layout.LinkReporting") }
                : null,
            { href: localePath("/logout"), title: t("Layout.LinkSignOut"), class: "font-bold" },
        ].filter((x) => !!x)
    );
}
